<template>
  <div class="d-flex align-items-center min-vh-100">
  
  </div>
</template>

<script>
export default {
  name: 'Register'
}
</script>
